import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCl-WQAz08hdVUqie07nR4jzV0wnNyaOuQ",
  authDomain: "releases-gold.firebaseapp.com",
  projectId: "releases-gold",
  storageBucket: "releases-gold.appspot.com",
  messagingSenderId: "69307614006",
  appId: "1:69307614006:web:0a21ae2a65b887b764a997",
};

firebase.initializeApp(firebaseConfig);
export { firebase };
