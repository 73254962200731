import { firebase } from "./firebase";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Helmet } from "react-helmet";

var QRCode = require("qrcode.react");

export function Detail() {
  const [branch, setBranch] = useState(null);
  const [currentBuild, setCurrentBuild] = useState(null);
  const [builds, setBuilds] = useState([]);
  let { appId, branchname } = useParams();

  useEffect(() => {
    const refreshBranches = async () => {
      let branchRequest = await firebase
        .firestore()
        .collection("apps")
        .doc(appId)
        .collection("branches")
        .doc(branchname)
        .get();

      if (branchRequest.exists) {
        let buildsRequest = await firebase
          .firestore()
          .collection("apps")
          .doc(appId)
          .collection("branches")
          .doc(branchname)
          .collection("builds")
          .orderBy("builtAt", "desc")
          .get();

        let buildDocs = buildsRequest.docs.map((x) => {
          let obj = x.data();
          obj.id = x.id;
          // obj.branchName = decodeURIComponent(x.id);
          return obj;
        });
        let firstBuild = buildDocs.shift();
        setCurrentBuild(firstBuild);
        setBuilds(buildDocs);
        let branchData = branchRequest.data();

        setBranch(branchData);
        document.title = `Builds for ${branchData.branchName}`;
      } else {
        setBranch(null);
      }
    };
    refreshBranches();
  }, [branchname, appId]);

  if (!branch) {
    return (
      <div>
        <div
          className="fixed top-0 right-0 w-full h-full bg-gray-50"
          aria-hidden="true"
        ></div>
        <div className="relative min-h-screen flex flex-col">
          {navbar()}
          <h1 className="text-center text-xl font-bold mt-4">
            No Builds Found
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>{`Builds for ${branch.linearTitle}`}</title>
      </Helmet>
      <div
        className="fixed top-0 right-0 w-full h-full bg-gray-50"
        aria-hidden="true"
      ></div>
      <div className="relative min-h-screen flex flex-col">
        {navbar()}

        <div className="flex-grow w-full max-w-2xl mx-auto xl:px-8 lg:flex">
          <div className="flex-1 min-w-0 bg-white xl:flex">
            <div className="bg-white lg:min-w-0 lg:flex-1">
              {branchInfoBar(branch)}
              {currentBuildPanel(currentBuild)}
              <div className="border-b border-t py-4 px-4 border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6">
                <h1 className="flex-1 text-md font-medium">Previous</h1>
              </div>
              <ul className="divide-y border-b divide-gray-200">
                {builds.map((build) => buildRow(build))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function statusIndicator(status) {
  if (!status) {
    return (
      <span
        className="h-4 w-4 bg-gray-100 rounded-full flex items-center justify-center"
        aria-hidden="true"
      >
        <span className="h-2 w-2 bg-gray-400 rounded-full"></span>
      </span>
    );
  }

  switch (status) {
    case "pending":
      return (
        <span
          className="h-4 w-4 bg-yellow-100 rounded-full flex items-center justify-center"
          aria-hidden="true"
        >
          <span className="h-2 w-2 bg-yellow-400 rounded-full"></span>
        </span>
      );
    case "success":
      return (
        <span
          className="h-4 w-4 bg-green-100 rounded-full flex items-center justify-center"
          aria-hidden="true"
        >
          <span className="h-2 w-2 bg-green-400 rounded-full"></span>
        </span>
      );
    case "failure":
      return (
        <span
          className="h-4 w-4 bg-red-100 rounded-full flex items-center justify-center"
          aria-hidden="true"
        >
          <span className="h-2 w-2 bg-red-400 rounded-full"></span>
        </span>
      );
    default:
      return (
        <span
          className="h-4 w-4 bg-gray-100 rounded-full flex items-center justify-center"
          aria-hidden="true"
        >
          <span className="h-2 w-2 bg-gray-400 rounded-full"></span>
        </span>
      );
  }
}

function navbar() {
  return (
    <nav className="flex-shrink-0 bg-gray-600">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex items-center px-2 lg:px-0 xl:w-64 space-x-2">
            <a href="/">
              <span className="inline-flex flex-row align-middle">
                <span className="text-xl text-white ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-left"
                  >
                    <polyline points="15 18 9 12 15 6"></polyline>
                  </svg>
                </span>

                <span className="text-xl font-medium text-white">Branches</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

function buildRow(build) {
  return (
    <li key={build.id}>
      <div className="px-4 py-4 sm:px-6 flex flex-row">
        <div className="flex flex-col items-right justify-between">
          <div className="mt-1 flex items-center space-x-4">
            {statusIndicator(build.status)}
            <span className="block text-sm text-gray-500">
              <p className="text-gray-700 ">
                {" "}
                {"commit " + build.commitHash.substring(0, 6)}{" "}
              </p>
              {`build ${build.status} at ${format(
                build.builtAt.toDate(),
                "h:mm aaaa M/d/yy"
              )}`}
            </span>
          </div>
        </div>
        <div className="flex-grow "></div>
        <div className="flex items-center">
          <a
            className="md:invisible"
            href={`itms-services://?action=download-manifest&amp;url=${build.installPlistUrl}`}
          >
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Install
            </button>
          </a>

          <button
            type="button"
            onClick={() =>
              navigator.clipboard.writeText(
                `itms-services://?action=download-manifest&amp;url=${build.installPlistUrl}`
              )
            }
            className="invisible w-0 md:w-16 md:visible relative align-middle inline-flex items-center md:px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span>Copy</span>
          </button>
        </div>
      </div>
    </li>
  );
}

function branchInfoBar(branch, branchname) {
  return (
    <div className="pl-4 pr-4 pt-4 pb-4 border-b border-t flex flex-row border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
      <a href={branch.pullRequestUrl}>
        <div className="flex items-center">
          <svg
            className="flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-gray-500"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.99917 0C4.02996 0 0 4.02545 0 8.99143C0 12.9639 2.57853 16.3336 6.15489 17.5225C6.60518 17.6053 6.76927 17.3277 6.76927 17.0892C6.76927 16.8762 6.76153 16.3104 6.75711 15.5603C4.25372 16.1034 3.72553 14.3548 3.72553 14.3548C3.31612 13.316 2.72605 13.0395 2.72605 13.0395C1.9089 12.482 2.78793 12.4931 2.78793 12.4931C3.69127 12.5565 4.16643 13.4198 4.16643 13.4198C4.96921 14.7936 6.27312 14.3968 6.78584 14.1666C6.86761 13.5859 7.10022 13.1896 7.35713 12.965C5.35873 12.7381 3.25756 11.9665 3.25756 8.52116C3.25756 7.53978 3.6084 6.73667 4.18411 6.10854C4.09129 5.88114 3.78244 4.96654 4.27251 3.72904C4.27251 3.72904 5.02778 3.48728 6.74717 4.65082C7.46487 4.45101 8.23506 4.35165 9.00028 4.34779C9.76494 4.35165 10.5346 4.45101 11.2534 4.65082C12.9717 3.48728 13.7258 3.72904 13.7258 3.72904C14.217 4.96654 13.9082 5.88114 13.8159 6.10854C14.3927 6.73667 14.7408 7.53978 14.7408 8.52116C14.7408 11.9753 12.6363 12.7354 10.6318 12.9578C10.9545 13.2355 11.2423 13.7841 11.2423 14.6231C11.2423 15.8247 11.2313 16.7945 11.2313 17.0892C11.2313 17.3299 11.3937 17.6097 11.8501 17.522C15.4237 16.3303 18 12.9628 18 8.99143C18 4.02545 13.97 0 8.99917 0Z"
              fill="currentcolor"
            />
          </svg>

          <h1 className="flex-1 pl-3 text-xs md:text-sm font-medium">
            <span className="font-mono font-semibold">{branch.branchName}</span>
          </h1>
        </div>
      </a>
      <div className="flex-grow px-1"></div>

      <div className="flex items-center">
        {branch.linearUrl ? (
          <a href={branch.linearUrl}>
            <svg
              width="22"
              height="22"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M123.974 131.918C125.354 130.725 126.7 129.473 128.011 128.163C157.33 98.8438 157.33 51.3083 128.011 21.9893C98.6917 -7.32975 51.1562 -7.32975 21.8372 21.9893C20.5268 23.2997 19.275 24.6464 18.0817 26.0263L123.974 131.918Z"
                fill="#5E6AD2"
              />
              <path
                d="M115.031 138.561L11.4394 34.9691C9.36173 38.2511 7.56337 41.6591 6.04431 45.1591L104.841 143.956C108.341 142.437 111.749 140.638 115.031 138.561Z"
                fill="#5E6AD2"
              />
              <path
                d="M93.2043 147.904L2.09574 56.7957C0.986981 61.2308 0.2884 65.7473 0 70.2851L79.715 150C84.2527 149.712 88.7693 149.013 93.2043 147.904Z"
                fill="#5E6AD2"
              />
              <path
                d="M63.3969 149.267L0.732938 86.6031C3.08389 101.816 10.1186 116.444 21.8372 128.163C33.5557 139.881 48.1844 146.916 63.3969 149.267Z"
                fill="#5E6AD2"
              />
            </svg>
          </a>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

function currentBuildPanel(currentBuild) {
  if (!currentBuild) {
    return <h1 className="text-lg font-medium">Build Not Found</h1>;
  }

  let mobileButton = <></>;
  let desktopButton = <></>;
  if (currentBuild.installPlistUrl && currentBuild.status === "success") {
    mobileButton = (
      <a
        className="md:hidden"
        href={`itms-services://?action=download-manifest&amp;url=${currentBuild.installPlistUrl}`}
      >
        <button
          type="button"
          className="mt-5 px-4 w-full py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Install
        </button>
      </a>
    );

    desktopButton = (
      <>
        <QRCode
          className="mt-2"
          value={
            "itms-services://?action=download-manifest&amp;url=" +
            currentBuild.installPlistUrl
          }
        />

        <button
          type="button"
          onClick={() =>
            navigator.clipboard.writeText(
              `itms-services://?action=download-manifest&amp;url=${currentBuild.installPlistUrl}`
            )
          }
          className="mt-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Copy Install URL
        </button>
      </>
    );
  }

  return (
    <div className="flex flex-row py-4 px-4">
      <div className="flex-col flex-1 ">
        <h1 className="flex-1 text-lg font-medium">Latest Build </h1>
        <h1 className="flex-1 text-sm text-grey-800">
          {" "}
          {format(currentBuild.builtAt.toDate(), "h:mm aaaa M/d/yy")}{" "}
        </h1>
        <div className="mt-1 flex items-center space-x-2 ">
          {statusIndicator(currentBuild.status)}
          <h2 className="text-sm text-grey-800"> {currentBuild.status}</h2>
        </div>
        <p className="mt-4 text-xs font-mono">
          {" "}
          {"commit " + currentBuild.commitHash}{" "}
        </p>
        <p className="text-xs font-mono">
          {" "}
          {"Author: " + currentBuild.commitAuthor}
        </p>
        <p className="text-xs font-mono">
          {" "}
          {"Date: " +
            format(
              new Date(currentBuild.commitDate * 1000),
              "h:mm aaaa M/d/yy"
            )}
        </p>
        <p className="mt-2 text-xs font-mono">{"commit message:"}</p>
        <p className="px-2 mt-2 mb-2 text-xs font-mono">
          {" "}
          {currentBuild.commitBody}
        </p>
        {mobileButton}
      </div>
      <div className=""></div>
      <div className="w-0 md:w-36 md:flex md:flex-col items-center invisible md:visible">
        {desktopButton}
      </div>
    </div>
  );
}
