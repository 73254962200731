import { firebase } from "./firebase";
import { formatDistance } from "date-fns";
import React, { useState, useEffect } from "react";
const logout = () => {
  firebase.auth().signOut();
};

export function Home() {
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);

  const [appId] = useState("com.circular.pixels");

  const handleChange = (e) => {
    if (e !== "") {
      let newList = [];
      newList = branches.filter((branch) => {
        return branch.branchName.includes(e) || branch.linearTitle.includes(e);
      });
      setFilteredBranches(newList);
    } else {
      setFilteredBranches(branches);
    }
  };

  useEffect(() => {
    const refreshBranches = async () => {
      let branchesRequest = await firebase
        .firestore()
        .collection("apps")
        .doc(appId)
        .collection("branches")
        .get();

      let branchDocPromises = branchesRequest.docs.map((x) => {
        let obj = x.data();
        obj.id = x.id;
        if (!obj.branchName) {
          obj.branchName = decodeURIComponent(x.id);
        }
        return firebase
          .firestore()
          .collection("apps")
          .doc(appId)
          .collection("branches")
          .doc(x.id)
          .collection("builds")
          .orderBy("builtAt", "desc")
          .limit(1)
          .get()
          .then((result) => {
            if (result.docs[0]) {
              let buildData = result.docs[0].data();
              obj.latestCommitHash = buildData.commitHash;
              obj.status = buildData.status;
              return obj;
            }
            return obj;
          });
      });
      let branchDocs = await Promise.all(branchDocPromises);
      branchDocs = branchDocs.sort(function(a, b) {
        var keyA = a.lastUpdated.toDate(),
          keyB = b.lastUpdated.toDate();
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
      setBranches(branchDocs);
      setFilteredBranches(branchDocs);
    };
    refreshBranches();
  }, [appId]);

  return (
    <div>
      <div
        className="fixed top-0 right-0 w-full h-full bg-gray-50"
        aria-hidden="true"
      ></div>
      <div className="relative min-h-screen flex flex-col">
        <nav className="flex-shrink-0 bg-gray-600">
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="flex items-center px-2 lg:px-0 xl:w-64">
                <a href="/">
                  <span className=" inline-block align-middle">
                    <span className="text-2xl">📦</span>{" "}
                    <span className="text-2xl font-medium text-white">
                      {" "}
                      Releases
                    </span>
                  </span>
                </a>
              </div>

              <div className="flex-1 flex justify-center lg:justify-end md:visible">
                <div className="w-full px-2 lg:px-6">
                  <label for="search" className="sr-only">
                    Search Branches
                  </label>
                  <div className="relative text-gray-200 focus-within:text-gray-400">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      id="search"
                      name="search"
                      onChange={(e) => handleChange(e.target.value)}
                      className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-400 bg-opacity-25 text-gray-100 placeholder-gray-200 focus:outline-none focus:bg-gray-500  focus:ring-0 focus:placeholder-gray-400 focus:text-gray-200 sm:text-sm"
                      placeholder="Search branches"
                      type="search"
                      autoComplete="off"
                    ></input>
                  </div>
                </div>
              </div>

              <div className="hidden lg:block lg:w-80">
                <div className="flex items-center justify-end">
                  <div className="flex">
                    <button
                      onClick={logout}
                      className="px-3 py-2 rounded-md text-sm font-medium text-gray-200 hover:text-white"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <div className="flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex">
          <div className="flex-1 min-w-0 xl:flex">
            <div className="xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200">
              <div className="pl-4 pr-6 py-0 sm:pl-6 lg:pl-8 xl:pl-0">
                <div className="flex items-center justify-between"></div>
              </div>
            </div>

            <div className="bg-white lg:min-w-0 lg:flex-1">
              <div className="pl-4 pr-6 pt-4 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
                <div className="flex items-center">
                  <h1 className="flex-1 text-lg font-medium">Branches</h1>
                </div>
              </div>
              <ul className="relative z-0 divide-y divide-gray-200 border-b border-gray-200">
                {filteredBranches.map((branch) => {
                  let detailPageUri = `/${appId}/branch/${branch.id}`;
                  return (
                    <a
                      href={detailPageUri}
                      className="relative text-sm text-gray-500 hover:text-gray-900 font-medium"
                    >
                      <li
                        key={branch.branchName}
                        className="relative pl-4 pr-6 py-5 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
                      >
                        <div className="flex items-center justify-between space-x-4">
                          <div className="min-w-0 space-y-3">
                            <div className="flex items-center space-x-3">
                              {statusIndicator(branch.status)}
                              <span className="block">
                                <h2 className="text-sm font-medium">
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  ></span>
                                  {branch.linearTitle || "Untitled"}
                                  <span className="sr-only">Running</span>
                                </h2>
                              </span>
                            </div>

                            <p className="relative group flex items-center space-x-2.5">
                              <svg
                                className="flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-gray-500"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M8.99917 0C4.02996 0 0 4.02545 0 8.99143C0 12.9639 2.57853 16.3336 6.15489 17.5225C6.60518 17.6053 6.76927 17.3277 6.76927 17.0892C6.76927 16.8762 6.76153 16.3104 6.75711 15.5603C4.25372 16.1034 3.72553 14.3548 3.72553 14.3548C3.31612 13.316 2.72605 13.0395 2.72605 13.0395C1.9089 12.482 2.78793 12.4931 2.78793 12.4931C3.69127 12.5565 4.16643 13.4198 4.16643 13.4198C4.96921 14.7936 6.27312 14.3968 6.78584 14.1666C6.86761 13.5859 7.10022 13.1896 7.35713 12.965C5.35873 12.7381 3.25756 11.9665 3.25756 8.52116C3.25756 7.53978 3.6084 6.73667 4.18411 6.10854C4.09129 5.88114 3.78244 4.96654 4.27251 3.72904C4.27251 3.72904 5.02778 3.48728 6.74717 4.65082C7.46487 4.45101 8.23506 4.35165 9.00028 4.34779C9.76494 4.35165 10.5346 4.45101 11.2534 4.65082C12.9717 3.48728 13.7258 3.72904 13.7258 3.72904C14.217 4.96654 13.9082 5.88114 13.8159 6.10854C14.3927 6.73667 14.7408 7.53978 14.7408 8.52116C14.7408 11.9753 12.6363 12.7354 10.6318 12.9578C10.9545 13.2355 11.2423 13.7841 11.2423 14.6231C11.2423 15.8247 11.2313 16.7945 11.2313 17.0892C11.2313 17.3299 11.3937 17.6097 11.8501 17.522C15.4237 16.3303 18 12.9628 18 8.99143C18 4.02545 13.97 0 8.99917 0Z"
                                  fill="currentcolor"
                                />
                              </svg>
                              <span className="text-sm text-gray-500 group-hover:text-gray-900 font-medium truncate">
                                {branch.branchName}
                              </span>
                            </p>
                          </div>
                          <div className="sm:hidden">
                            <svg
                              className="h-5 w-5 text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
                            <p className="flex items-center space-x-4">
                              View details
                              <button
                                className="relative bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                type="button"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-chevron-right text-gray-500"
                                >
                                  <polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                              </button>
                            </p>
                            <p className="flex text-gray-500 text-xs space-x-2">
                              <span className="font-mono">
                                {branch.latestCommitHash.substring(0, 6)}
                              </span>
                              <span aria-hidden="true">&middot;</span>
                              <span>
                                Last updated{" "}
                                {formatDistance(
                                  branch.lastUpdated.toDate(),
                                  new Date()
                                )}{" "}
                                ago
                              </span>
                            </p>
                          </div>
                        </div>
                      </li>
                    </a>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="bg-gray-50 pr-4 sm:pr-6 lg:pr-8 lg:flex-shrink-0 lg:border-l lg:border-gray-200 xl:pr-0">
            <div className="pl-6 lg:w-80"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function statusIndicator(status) {
  if (!status) {
    return (
      <span
        className="h-4 w-4 bg-gray-100 rounded-full flex items-center justify-center"
        aria-hidden="true"
      >
        <span className="h-2 w-2 bg-gray-400 rounded-full"></span>
      </span>
    );
  }

  switch (status) {
    case "pending":
      return (
        <span
          className="h-4 w-4 bg-yellow-100 rounded-full flex items-center justify-center"
          aria-hidden="true"
        >
          <span className="h-2 w-2 bg-yellow-400 rounded-full"></span>
        </span>
      );
    case "success":
      return (
        <span
          className="h-4 w-4 bg-green-100 rounded-full flex items-center justify-center"
          aria-hidden="true"
        >
          <span className="h-2 w-2 bg-green-400 rounded-full"></span>
        </span>
      );
    case "failure":
      return (
        <span
          className="h-4 w-4 bg-red-100 rounded-full flex items-center justify-center"
          aria-hidden="true"
        >
          <span className="h-2 w-2 bg-red-400 rounded-full"></span>
        </span>
      );
    default:
      return (
        <span
          className="h-4 w-4 bg-gray-100 rounded-full flex items-center justify-center"
          aria-hidden="true"
        >
          <span className="h-2 w-2 bg-gray-400 rounded-full"></span>
        </span>
      );
  }
}
